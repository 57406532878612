import {
  ref,
  onMounted,
  reactive,
  watch,
  getCurrentInstance,
  onBeforeUnmount
} from 'vue'
import {
  companyUseCase
} from '@/domain/usecase'
import { useRoute, useRouter } from 'vue-router'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { useStore } from 'vuex'
import { isEmpty } from 'lodash'

export default {
  name: 'AccountVerification',
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $socketHub
    } = app!.appContext.config.globalProperties
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    const isLoading = ref(false)
    const dataForm = ref()
    const op = ref()
    const columns = [{
      title: 'Nama Pelanggan'
    },
    {
      title: 'Kota'
    },
    {
      title: 'Kode Referral'
    },
    {
      title: 'Email'
    }, {
      title: 'No. Telp'
    }, {
      title: ''
    }]
    const dataSource = ref([]) as any
    const data = ref([])
    const totalRecords = reactive({
      count: 0
    })
    const inputSearch = ref('')

    const mappingData = (value: any) => {
      /* eslint-disable-next-line */
      value.map((x: any) => {
        // const item = null
        x.Level = 0
        const mapData = x
        dataSource.value.push(mapData)
        /* eslint-disable-next-line */
        x.Branchs.map((val: any) => {
          val.Level = 1
          const tmp = val
          dataSource.value.push(tmp)
          // console.log(dataSource)
        })
      })
    }
    const getAllData = async () => {
      store.dispatch('showLoading')
      await companyUseCase.getMinimalInfoUnverifiedUsersCompany(window.location.search).then((response) => {
        if (!response.error) {
          console.log('res', response.result)
          data.value = response.result
          totalRecords.count = response.result.Count
          if (totalRecords.count > 0) {
            dataSource.value = []
            mappingData(response.result.Data)
          }
          isLoading.value = true
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
        store.dispatch('hideLoading')
      })
    }

    const goToDetail = (val: any) => {
      router.push({
        name: 'master-account-verification-detail',
        params: {
          id: encryptDecriptMethods.encrypt(val.Id)
        }
      })
    }

    const nameEmpty = (val: any) => {
      if (isEmpty(val)) {
        return '-'
      } return val
    }

    const onPage = () => {
      getAllData()
    }

    const submitSearch = (val: any) => {
      router.replace({
        // name: route.name,
        query: {
          CompanyName: val
        }
      }).then(() => {
        getAllData()
      }).catch(() => {
        console.log()
      })
    }

    const showDropDown = (evt: any, val: any) => {
      op.value.toggle(evt)
      dataForm.value = val
    }

    onMounted(() => {
      $socketHub.on('reloadVerify', getAllData)
      getAllData()
      // mappingData()
    })

    onBeforeUnmount(() => {
      $socketHub.off('reloadVerify')
    })
    return {
      dataSource,
      goToDetail,
      columns,
      nameEmpty,
      totalRecords,
      onPage,
      route,
      showDropDown,
      dataForm,
      op,
      inputSearch,
      submitSearch,
      isLoading
    }
  }
}
